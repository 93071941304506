<template>
  <v-card class="pa-3">
    <template v-for="(field, i) in fields.fields">
      <v-text-field
        v-if="field.type == 'Text'"
        class="mb-3"
        :key="i"
        :label="field.label || field.key"
        v-model="item[field.key]"
        filled
        hide-details
        :disabled="item[fields.locked]"
        :append-icon="item[fields.locked] ? 'mdi-lock' : ''"
      >
      </v-text-field>

      <v-textarea
        v-else-if="field.type == 'Descripcion'"
        class="mb-3"
        :key="i"
        :label="field.label || field.key"
        v-model="item[field.key]"
        filled
        hide-details
        :disabled="item[fields.locked]"
        :append-icon="item[fields.locked] ? 'mdi-lock' : ''"
        no-resize
        auto-grow
        rows="3"
      >
      </v-textarea>

      <v-select
        v-else-if="field.type == 'Relationship'"
        class="mb-3"
        :key="i"
        :label="field.label || field.key"
        v-model="item[field.key]"
        filled
        hide-details
        :disabled="item[fields.locked]"
        :append-icon="item[fields.locked] ? 'mdi-lock' : ''"
        :items=" fields.fields.find( f => f.key == field.key ).rel "
      >
      </v-select>

      <v-autocomplete
        v-else-if="field.type == 'Icon'"
        class="mb-3"
        :key="i"
        v-model="item[field.key]"
        :items="icons"
        filled
        hide-details
        prepend-inner-icon="mdi-magnify"
        clearable
      >
        <template v-slot:selection="{ item }">
          <v-icon left>{{ item }}</v-icon>
          {{ item }}
        </template>
        <template v-slot:item="{ item }">
          <v-icon left>{{ item }}</v-icon>
          {{ item }}
        </template>
      </v-autocomplete>

      <div
        :key="i"
        v-else-if="field.type == 'Color'"
        class="d-flex flex-column align-center pa-2"
      >
      <v-subheader>{{ field.label || field.key }}</v-subheader>
      <v-divider></v-divider>
        <v-color-picker
          class="mb-3 mx-auto"
          :elevation="2"
          show-swatches
          width="400"
          v-model="colorsAux[field.key]"
          @input="item[field.key] = colorsAux[field.key].hex"
          @change="item[field.key] = colorsAux[field.key].hex"
          mode="hexa"
        >
        </v-color-picker>
      </div>

      <v-item-group
        :key="i"
        v-else-if="Array.isArray(field.type)"
        v-model="item[field.key]"
        class="my-3"
      >
        <v-subheader>{{ field.label || field.key }}</v-subheader>
        <v-row>
          <v-col v-for="(choice, k) in field.type" :key="k">
            <v-item
              :value="choice.value"
              active-class="active-icon"
              v-slot="{ active, toggle }"
            >
              <v-card
                @click.stop="toggle"
                style="position: relative;"
                height="125px"
                outlined
                class="pa-3 d-flex justify-center align-center"
              >
                <v-slide-x-transition>
                  <v-icon v-if="active" color="primary" class="item-icon"
                    >mdi-check</v-icon
                  >
                </v-slide-x-transition>
                <h3>{{ choice.label }}</h3>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </template>

    <v-divider class="mt-3"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="item[fields.id]"
        @click.stop="eliminar"
        color="error darken-2"
        :disabled="item[fields.locked]"
      >
        <v-icon v-if="item[fields.id]" left>mdi-lock</v-icon>
        <v-icon v-else left>mdi-delete</v-icon>
        Eliminar
      </v-btn>
      <v-btn @click.stop="guardar" color="primary">
        <v-icon left>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-card-actions>

    <ConfirmDialog ref="confirm" />

  </v-card>
</template>

<script>
import * as icons from "@mdi/js";
import colors from "vuetify/lib/util/colors";

export default {
  props: {
    fields: Object,
    _item: Object,
    router_prefix: String,
  },
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      item: { ...this.fields.fields.reduce((a, k) => ({ ...a, [k.key]: null }), {}), ...this._item },
      // colorsAux: Object.entries(this._item).map( ([key, val]) => ({ key : this.hexColor( val ) }) ),
      colorsAux: Object.keys(this._item).reduce((a, k) => {
        const field = this.fields.fields.find((f) => f.key == k)
        if ( field && field.type == 'Color' )
          return { ...a, [k]: this.hexColor(this._item[k], k) };
        return a
      }, {}),
      icons: Object.keys(icons).map((string) =>
        string
          .replace(/([a-z])([A-Z])/g, "$1-$2")
          .replace(/\s+/g, "-")
          .toLowerCase()
      ),
    };
  },
  methods: {
    hexColor(name, key) {
      if (this.fields.fields.find((f) => f.key == key).type != "Color")
        return null;
      let auxColors = { ...this.$vuetify.theme.currentTheme };
      Object.keys(auxColors).map(
        (key, index) => (auxColors[key] = { base: auxColors[key] })
      );
      const allColors = { ...colors, ...auxColors };
      if (name[0] == "#") return name;
      const [nameFamily, nameModifier] = name.split(" ");
      const shades = ["black", "white", "transparent"];
      const util = { family: null, modifier: null };
      if (shades.find((shade) => shade === nameFamily)) {
        util.family = "shades";
        util.modifier = nameFamily;
      } else {
        const [firstWord, secondWord] = nameFamily.split("-");
        util.family = `${firstWord}${
          secondWord
            ? secondWord.charAt(0).toUpperCase() + secondWord.slice(1)
            : ""
        }`;
        util.modifier = nameModifier ? nameModifier.replace("-", "") : "base";
      }
      return allColors[util.family][util.modifier];
    },
    async guardar() {
      const nuevo = { ...this.item };
      try {
        if (nuevo[this.fields.id] != null) {
          const { data } = await axios({
            method: "PATCH",
            url: `${process.env.VUE_APP_API_URL}/${this.router_prefix}/${
              nuevo[this.fields.id]
            }`,
            data: nuevo,
          });
        } else {
          const { data } = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_URL}/${this.router_prefix}`,
            data: [nuevo],
          });
        }
        this.$root.$emit("snack", "Se ha guardado correctamente");
        this.$emit("reload");
      } catch (err) {
        console.error(err);
        this.$root.$emit("snack", "No se ha podido guardar", 'error');
      }
    },
    async eliminar() {
      if (
        !(await this.$refs.confirm.open(
          "Eliminar estado",
          "¿Estás seguro? Esta acción no se puede deshacer",
          { confirmColor: "error", color: 'error', width: 600 }
        ))
      )
        return;

      axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_API_URL}/${this.router_prefix}/${
          this.item[this.fields.id]
        }`,
      })
        .then((res) => {
          this.$root.$emit("snack", "Se ha eliminado correctamente");
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido eliminar", "error");
        });
    },
  },
  watch: {
    colorsAux: {
      handler(v) {
        Object.entries( v ).forEach( ([ key, val ]) => {
          this.$set( this.item, key, val.hex )
        } )
      },
      deep: true,
    },
  },
};
</script>

<style>
.active-item {
  border-color: var(--v-primary-base) !important;
  color: var(--v-primary-base) !important;
}
.item-icon {
  position: absolute !important;
  top: 5px;
  left: 5px;
}
</style>
